

function MainHome2(){


  return(
    <div className="mh2">
    <div className="returnitem">
    <img src={require(`../img/banar.jpg`)} alt="상품배너" width="100%" />

    </div>
  

    </div>
  )
}

export default MainHome2