

function MainHome() {
  return (
    <div>
      <div className="opgg"></div>
      <div className="hh">
        <div className="jj">
          <h1 className="k1">2025 홀리데이 스페셜 딜</h1>
          <p className="cpa1">홀리데이 한정 혜택과 함께<br />
            다이슨 테크놀로지의 특별함을 선물해 보세요
          </p>
          <br /><br />
          <h3 className="k2">2025.12.1 - 2025.12.31 </h3>
          <br /><br />
          <p className="cap2"><a href="#">구매하기</a></p>
        </div>
      </div>
    </div>
  )
}

export default MainHome